import { ApiService } from 'src/app/services/api.service'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'
import { UIHelpers } from 'src/app/helpers/ui-helpers'
import { DataService } from './data.service'
import { Resp } from 'src/app/interfaces/response'
import { Component, TemplateRef } from '@angular/core'
import { Router, ActivatedRoute, RouterLink } from '@angular/router'
import { Pagination } from 'src/app/interfaces/response'
import { Transaction } from 'src/app/models/transactions'
import moment, { Moment } from 'moment'
import { IAlertService } from 'src/app/libs/ialert/ialerts.service'
import { ConstantsService } from 'src/app/services/constants.service'

@Component({
    selector: 'app-my-wallet',
    templateUrl: './transactions.component.html',
    styleUrls: ['./transactions.component.css']
})
export class TransactionsComponent {
    dataStatus = 'fetching'
    moment = moment
    reportTime: any
    openingBalance: number = 0
    currentBalance = 0
    pageOpeningBalance = 0
    paymentAmount = 0
    totalBalance: Array<number> = []
    walletList: Array<Transaction> = []
    pagination: Pagination<any>
    modalRef: BsModalRef
    filters: any = {
        startDate: '',
        endDate: '',
        page: 1
    }
    breadCrum = [
        {
            link: '/home/transactions/',
            value: 'Transactions'
        }
    ]
    loaderOptions = {
        rows: 5,
        cols: 5,
        colSpans: {
            0: 1
        }
    }
    waiting: {
        search: boolean
        save: boolean
        payNow: boolean
    }

    constructor(
        private ds: DataService,
        public ui: UIHelpers,
        public router: Router,
        private route: ActivatedRoute,
        private alert: IAlertService,
        public cs: ConstantsService,
        private ms: BsModalService,
        public api: ApiService
    ) {
        this.waiting = {
            search: false,
            save: false,
            payNow: false
        }

        this.route.queryParams.subscribe((params) => {
            if (params.page) {
                this.filters.page = params.page
            }

            if (params.startDate) {
                this.filters.startDate = new Date(params.startDate)
            }

            if (params.endDate) {
                this.filters.endDate = new Date(params.endDate)
            }
            if (params) {
                this.search()
            }
        })
    }

    checkTotal(total: number, pageOpeningBalance: number) {
        const lastIndex = this.walletList.length - 1
        this.totalBalance[lastIndex] =
            Number(total) + Number(pageOpeningBalance) + Number(this.walletList[lastIndex]?.amount)

        for (let j = lastIndex - 1; j >= 0; j--) {
            this.totalBalance[j] =
                Number(this.totalBalance[j + 1]) + Number(this.walletList[j].amount)
        }
    }

    openPayNowModal(modal: TemplateRef<any>) {
        this.modalRef = this.ms.show(modal, {
            class: 'modal-sm modal-dialog-centered back-office-panel',
            backdrop: 'static',
            ignoreBackdropClick: true,
            keyboard: false
        })
    }

    setPagination(page: number) {
        this.filters.page = page
        const params = { ...this.filters }
        // params.endDate = moment(params.endDate).format('YYYY-MM-DD')
        // params.startDate = moment(params.startDate).format('YYYY-MM-DD')
        this.router.navigate(['/student/transactions'], { queryParams: params, replaceUrl: true })
    }
    printdiv() {
        window.print()
    }

    search(): void {
        this.openingBalance = 0
        if (this.filters.startDate !== '' && this.filters.endDate !== '') {
            if (this.filters.endDate < this.filters.startDate) {
                this.alert.error('End Date must be Greater than Start Date')
                return
            }
        }

        this.waiting.search = true

        const params = { ...this.filters }
        if (params.endDate !== '') {
            params.endDate = moment(params.endDate).format('YYYY-MM-DD')
        }
        if (params.startDate !== '') {
            params.startDate = moment(params.startDate).format('YYYY-MM-DD')
        }

        this.ds.getMyWallet(params).subscribe((resp: Resp<any>) => {
            this.waiting.search = false
            if (resp.success === true) {
                this.walletList = resp.data?.data?.data
                this.pagination = resp.data?.data as Pagination
                this.dataStatus = 'done'
                this.openingBalance = resp.data.openingBalance
                this.pageOpeningBalance = resp.data.pageOpeningBalance
                this.currentBalance = resp.data.currentBalance
                this.paymentAmount = this.currentBalance < 0 ? this.currentBalance * -1 : 100

                this.checkTotal(this.openingBalance, resp.data.pageOpeningBalance)
            }
        })
    }

    // payNow(): void {
    //     this.waiting.payNow = true
    //     this.ds.payNow({ amount: this.paymentAmount }).subscribe((resp: Resp<any>) => {
    //         this.waiting.payNow = false
    //         if (resp.success) {
    //             this.modalRef.hide()
    //             window.location.href = resp.data
    //         } else {
    //             this.alert.error(`Can't pay now. Please try again later.`)
    //         }
    //     })
    // }

    printDiv() {
        this.reportTime = moment().format('MMMM DD YYYY, h:mm A')
        setTimeout(() => {
            window.print()
        }, 500)
    }
}
