<div class="no-print-me">
    <h1 class="title">Transactions</h1>
    <app-breadcrums [data]="breadCrum"></app-breadcrums>
    <div>
        <div class="row" style="display: flex;  align-items: flex-end; margin: 15px 0px;">
            <div class="col-md-3 cg-input-group">
                <label>Start Date</label>
                <input type="text" class="cg" [(ngModel)]="filters.startDate" bsDatepicker [bsConfig]="{ containerClass: 'theme-orange', dateInputFormat: this.cs.DATE_TIME_FORMAT.SYN_DATE, adaptivePosition: true, showWeekNumbers: false }" placeholder="Start Date" />
            </div>
            <div class="col-md-3 cg-input-group">
                <label>End Date</label>
                <input type="text" class="cg" [(ngModel)]="filters.endDate" bsDatepicker [bsConfig]="{ containerClass: 'theme-orange', dateInputFormat: this.cs.DATE_TIME_FORMAT.SYN_DATE, adaptivePosition: true, showWeekNumbers: false }" placeholder="End Date" />
            </div>

            <div class="col cg-input-group d-flex justify-content-between align-items-end">
                <div class="btn-group" dropdown>
                    <button [disabled]="waiting.search" [ngClass]="waiting.search ? 'in-progress' : ''" type="button" class="cg secondary" (click)="filters.page = 1; search()">
                        Search
                        <span></span>
                        <span></span>
                        <span></span>
                    </button>
                </div>
            </div>

            <div class="blnc" style="width: 300px;">
                <label>Current Balance: </label>
                <h3 class="text-right" *ngIf="currentBalance >= 0">${{ currentBalance | number: '1.2-2' }}</h3>
                <h3 class="text-right" *ngIf="currentBalance < 0">-${{ currentBalance * -1 | number: '1.2-2' }}</h3>
            </div>
        </div>
    </div>

    <!-- <div class="d-flex justify-content-between">
    <div class="actions">
        <div class="blnc">
            <label>Opening Balance: </label>
            <h3 class="text-right" *ngIf="openingBalance >= 0">${{ openingBalance | number: '1.0-2' }}</h3>
            <h3 class="text-right" *ngIf="openingBalance < 0">${{ openingBalance * -1 | number: '1.0-2' }}</h3>
        </div>

        <div class="blnc">
            <label>Current Balance: </label>
            <h3 class="text-right" *ngIf="currentBalance >= 0">${{ currentBalance | number: '1.0-2' }}</h3>
            <h3 class="text-right" *ngIf="currentBalance < 0">${{ currentBalance * -1 | number: '1.0-2' }}</h3>
        </div>
    </div>
</div> -->
<div class="box">
    <div class="table-responsive">
        <table class="cg primary">
            <thead>
                <tr>
                    <th style="width: 20%;">Date</th>
                    <th style="width: 20%;">Description</th>
                    <th style="width: 20%;">Debit</th>
                    <th style="width: 20%;">Credit</th>
                    <th style="width: 20%;">Balance</th>
                </tr>
            </thead>

            <tbody *ngIf="dataStatus === 'fetching'" [appSkeletonTabelLoader]="loaderOptions"></tbody>

            <tbody *ngIf="walletList.length > 0">
                <tr *ngFor="let d of walletList; let i = index">
                    <!-- <td>{{moment.utc(d.created_at).local().format('MM/DD/yyyy')}}</td> -->
                    <td>{{ d.dated}}</td>
                    <td>{{ d.description }}</td>
                    <td class="amount text-right">
                        <span *ngIf="d.amount < 0">${{ d.amount * -1 | number: '1.2-2' }}</span>
                    </td>
                    <td class="amount text-right">
                        <span *ngIf="d.amount >= 0">${{ d.amount | number: '1.2-2' }}</span>
                    </td>
                    <td class="amount text-right">
                        <span *ngIf="totalBalance[i] >= 0">${{ totalBalance[i] | number: '1.2-2' }}</span>
                        <span *ngIf="totalBalance[i] < 0">-${{ totalBalance[i] * -1 | number: '1.2-2' }}</span>
                    </td>
                </tr>
            </tbody>

            <tbody *ngIf="dataStatus === 'done' && walletList.length === 0">
                <tr>
                    <td colspan="6">
                        <app-no-data-found></app-no-data-found>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <ng-template #paymentModal>
        <div class="modal-body">
            <h2 class="title">Online Payment</h2>
            <div class="input-group">
                <label>How much amount you want to pay?</label>
                <div class="group">
                    <div class="pre">
                        <button class="secondary">USD</button>
                    </div>
                    <input type="text" class="text-right" placeholder="Amount" [(ngModel)]="paymentAmount" />
                </div>
            </div>

            <!-- <div class="d-flex justify-content-end">
                <button type="button" class="secondary" (click)="modalRef.hide()">Cancel</button>
                <button [disabled]="waiting.payNow" [ngClass]="{ 'in-progress': waiting.payNow }" type="button" class="primary ml-1" (click)="payNow()">
                    Proceed to Payment
                    <span></span>
                    <span></span>
                    <span></span>
                </button>
            </div> -->
        </div>
    </ng-template>

    <app-ipagination [pagination]="pagination" [activePage]="filters.page" (pageChangeEvent)="setPagination($event)" *ngIf="dataStatus === 'done'"></app-ipagination>
</div>
</div>
<div class="print-me report">
    <div class="header">
        <img src="assets/images/logo.png" />
        <div class="details">
            <h2>United Cheerleading &amp; Tumbling</h2>
            <p class="title">Transaction History</p>
            <p class="time">{{ reportTime }}</p>
        </div>
    </div>

    <div class="d-flex justify-content-end">
        <div>
            <label>Current Balances :</label>
            <span class="primary" *ngIf="currentBalance >= 0">${{ currentBalance | number: '1.2-2' }}</span>
            <span class="primary" *ngIf="currentBalance < 0">-${{ currentBalance * -1 | number: '1.2-2' }}</span>
        </div>
    </div>

    <table class="printable invoice">
        <thead>
            <tr>
                <th class="amount text-center print-head">Date</th>
                <th class="print-head">Description</th>
                <th class="amount text-center print-head">Debit</th>
                <th class="amount text-center print-head">Credit</th>
                <th class="amount text-center print-head">Balance</th>
            </tr>
        </thead>
        <tbody *ngIf="walletList.length > 0">
            <tr *ngFor="let d of walletList; let i = index">
                <td>{{ d.dated | date: 'MM/dd/yyyy' }}</td>
                <td>{{ d.description }}</td>
                <td class="amount text-right">
                    <span *ngIf="d.amount < 0">${{ d.amount * -1 | number: '1.2-2' }}</span>
                </td>
                <td class="amount text-right">
                    <span *ngIf="d.amount > 0">${{ d.amount | number: '1.2-2' }}</span>
                </td>
                <td class="amount text-right">
                    <span *ngIf="totalBalance[i] >= 0">${{ totalBalance[i] | number: '1.2-2' }}</span>
                    <span *ngIf="totalBalance[i] < 0">-${{ totalBalance[i] * -1 | number: '1.2-2' }}</span>
                </td>
            </tr>
        </tbody>

        <tbody *ngIf="dataStatus === 'done' && walletList.length === 0">
            <tr>
                <td colspan="6">
                    <app-no-data-found></app-no-data-found>
                </td>
            </tr>
        </tbody>
    </table>
</div>
